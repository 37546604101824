<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="" style="margin-top: 30rpx">
      <div class="banner-wrap">
        <div class=" " style="color: #f0f2f2; font-size: 16px"><router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>辨病论治</a><i class="el-icon-arrow-right"></i><a style="color: #666666">辨病论治系统</a></div>
      </div>
    </div>
    <div class="startBox">
      <div class="startBoxleft">
        <!-- <div class="" style="display: flex;" v-if="ageCurrent == -1">
                    <div style="margin-right: 20px;">{{queIndex + 1}}</div>
                    <el-progress :percentage="percentnum"></el-progress>
                    <div style="margin-left: 20px;">{{QuestionTotal}}</div>
                </div> -->
            <div class="flex-l-b">
              <div class="" style="font-size: 16px; font-weight: bold"> </div>
                <div class="banner-right">
                <span @click="toPage">返回上一步</span>
              </div>
            </div>
        <div class="quebox">
          <div class="test-tip" style="display: flex; margin-bottom: 10px">
            你选择的症状是:<span>{{ quename }}</span>
          </div>
          <div class="test-tip">
            请根据您的体验和感觉，回答以下问题：
            <!-- <text>注：本品不适合孕妇</text> -->
          </div>
          <div class="" v-if="ageCurrent == 1 && sexName == null">
            <div class="queston-tit">请选择您的性别</div>
            <div class="" v-for="(optionSex, indexSex) in sex" :key="indexSex">
              <div class="question-option" @click="gosexNext(optionSex, indexSex)">
                <div style="display: flex; align-items: center" class="">
                  <div class="titleBoxTextimage">
                    <img :src="indexSex == answerSex ? urlCheck : noUrlCheck" />
                  </div>
                  <div :class="indexSex == answerSex ? 'active titleBoxText' : ' titleBoxText'" style="flex: 1; width: 100%">
                    {{ optionSex.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="ageCurrent == 2 && ageName == null">
            <div class="queston-tit">请选择您的年龄</div>
            <div class="" v-for="(optionAge, indexAge) in age" :key="indexAge">
              <div class="question-option" @click="goageNext(optionAge, indexAge)">
                <div style="display: flex; align-items: center" class="">
                  <div class="titleBoxTextimage">
                    <img :src="indexAge == answerAge ? urlCheck : noUrlCheck" />
                  </div>
                  <div :class="indexAge == answerAge ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                    {{ optionAge.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="ageCurrent == -1">
            <div class="" v-for="(item, index) in questionList" :key="index">
              <div class="" v-if="index == queIndex">
                <div class="queston-tit">
                  （{{ queIndex + 1 }}/{{ QuestionTotal }})【{{ item.type == "Radio" ? "单选" : "多选" }}】{{ item.description }}
                  <div class="optionsImgflexbox" v-if="item.fileUrl">
                    <div class="optionsImgbox" v-for="(itemimg, indeximg) in item.fileUrl" :key="indeximg" @click.stop="imagePre(httpContent + itemimg)">
                      <img :src="http + itemimg" />
                    </div>
                  </div>
                </div>
                <div class="" v-if="item.type == 'Radio' && !item.imgTrue">
                  <div v-for="(option, Oindex) in item.selfDiagnosisQuestionOption" :key="Oindex" @click="bindChangeage(option, Oindex, item)">
                    <div class="question-option">
                      <div style="display: flex; align-items: center" class="">
                        <div class="titleBoxTextimage">
                          <img :src="item.optionId == option.id || indexRa == Oindex ? urlCheck : noUrlCheck" />
                        </div>
                        <div :class="item.optionId == option.id || indexRa == Oindex ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                          {{ option.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-if="item.type == 'Radio' && item.imgTrue">
                  <div v-for="(option, Oindex) in item.selfDiagnosisQuestionOption" :key="Oindex" @click="bindChangeageradio(option, Oindex, item)">
                    <div class="question-option">
                      <div style="display: flex; align-items: center" class="">
                        <div class="titleBoxTextimage">
                          <img :src="item.optionId == option.id || indexRa == Oindex ? urlCheck : noUrlCheck" />
                        </div>
                        <div :class="item.optionId == option.id || indexRa == Oindex ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                          {{ option.description }}
                        </div>
                      </div>
                    </div>
                    <div class="optionsImgflexbox" v-if="option.fileUrl.length != 0 && option.fileUrl != null">
                      <div class="optionsImgbox" v-for="(optionimg, indexoptionimg) in option.fileUrl" :key="indexoptionimg" @click.stop="imagePre(httpContent + optionimg)">
                        <img :src="http + optionimg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-if="item.type == 'Multiple' && item.imgTrue">
                  <div v-for="(option, Oindex) in item.selfDiagnosisQuestionOption" :key="Oindex" @click="gosearchimgMultiple(option, Oindex, item)">
                    <div class="question-option">
                      <div style="display: flex; align-items: center" class="titleBoxText">
                        <div class="titleBoxTextimage" v-if="option.ischecked" style="opacity: 0.8">
                          <img :src="noUrlCheckM" />
                        </div>
                        <div class="titleBoxTextimage" v-else>
                          <img :src="option.checked ? urlCheckM : noUrlCheckM" />
                        </div>
                        <div v-if="option.ischecked" class="titleBoxText" style="flex: 1; width: 100%; opacity: 0.8">
                          {{ option.description }}
                        </div>
                        <div v-else :class="option.checked ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                          {{ option.description }}
                        </div>
                      </div>
                    </div>
                    <div class="optionsImgflexbox" v-if="option.fileUrl.length != 0 && option.fileUrl != null">
                      <div class="optionsImgbox" v-for="(optionimg, indexoptionimg) in option.fileUrl" :key="indexoptionimg" @click.stop="imagePre(httpContent + optionimg)">
                        <img :src="http + optionimg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" v-if="item.type == 'Multiple' && !item.imgTrue">
                  <div v-for="(option, Oindex) in item.selfDiagnosisQuestionOption" :key="Oindex" @click="gotextopMultiple(option, Oindex, item)">
                    <div class="question-option">
                      <div style="display: flex; align-items: center" class="titleBoxText">
                        <div class="titleBoxTextimage" v-if="option.ischecked" style="opacity: 0.8">
                          <img :src="noUrlCheckM" />
                        </div>
                        <div class="titleBoxTextimage" v-else>
                          <img :src="option.checked ? urlCheckM : noUrlCheckM" />
                        </div>
                        <div v-if="option.ischecked" class="titleBoxText" style="flex: 1; width: 100%; opacity: 0.8">
                          {{ option.description }}
                        </div>
                        <div v-else :class="option.checked ? 'active titleBoxText' : 'titleBoxText'" style="flex: 1; width: 100%">
                          {{ option.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" style="color: #8c8b8b; margin-top: 40rpx" v-if="queIndex == questionList.length">
                  <div class="" style="padding: 12rpx">本测试仅作为诊断和治疗的参考。对于已经确诊的疾病，应按照医嘱积极治疗。所提及的内容仅作为生活保健的咨询建议，如遇不适请及时就医。</div>
                </div>
                <div class="flexlr" style="align-items: center; margin-top: 40rpx">
                  <div class="prev-btn" @click="upQuestion(item)" v-if="queIndex >= 1">上一题</div>
                  <div class="prev-btn" style="color: #999999; background: #f2f2f2" v-if="queIndex < 1">上一题</div>
                  <div class="next-btn" @click="NextQuestion(item, queindex)" v-if="queIndex < questionList.length - 1 && item.optionId">下一题</div>
                  <div class="next-btn" v-if="queIndex < questionList.length && item.type == 'Multiple' && !item.optionIdMultiple" @click="chooseAnswerMultiple(item)">确定(多选)</div>
                  <div class="result-btn" v-if="queIndex == questionList.length - 1 && (questionList[queIndex].optionId || questionList[queIndex].optionIdMultiple)" @click="goresult">查看结果</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
import { Test } from "../../components/company/test";
export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    var test = new Test(this.TokenClient, this.Services.Statics);
    return {
      zdrugconsultationMain: zdrugconsultation,
      testDomain: test,
      sexList: [
        {
          name: "男",
        },
        {
          name: "女",
        },
      ],
      ageList: [],
      categoryList: [],
      categoryindex: 0,
      imgtextList: [],
      imgtextindex: -1,
      textindex: -1,
      modalName: null,
      selfId: "",
      sexShow: true,
      sexName: "",
      sexindex: 0,
      ageShow: false,
      ageName: "",
      ageindex: 0,
      questionList: [],
      processList: [],
      questindex: 0,
      opcategoryindex: -1,
      errdata: "",
      optionname: {},
      http: "",
      indexop: [0],
      answerSex: -1,
      answerAge: -1,
      sex: [
        {
          text: "男",
          value: 0,
        },
        {
          text: "女",
          value: 1,
        },
      ],
      age: [
        {
          text: "6岁以下",
          value: 0,
        },
        {
          text: "7-12岁",
          value: 1,
        },
        {
          text: "13-17岁",
          value: 2,
        },
        {
          text: "18-45岁",
          value: 3,
        },
        {
          text: "46-69岁",
          value: 4,
        },
        {
          text: "69岁以上",
          value: 5,
        },
      ],
      ageCurrent: 1,
      CurrentIndex: 0,
      httpContent: "",
      QuestionTotal: 0,
      percentnum: 0,
      quename: "",
      queIndex: 0,
      nextClick: true,
      baseFontSize: 18,
      indexRa: -1,
      urlCheck: require("@/assets/img/zdrugConsult/zradio.png"),
      noUrlCheck: require("@/assets/img/zdrugConsult/zradiono.png"),
      urlCheckM: require("@/assets/img/zdrugConsult/zcheck.png"),
      noUrlCheckM: require("@/assets/img/zdrugConsult/znocheck.png"),
      selectAnswerUser: this.$route.query.selectAnswerUser,
      noTexttype:'z1',
      isgoId:this.$route.query.isgoId,
      isgoData:{},
      isgoDataList:[],
      resultTrue:false
    };
  },
  beforeDestroy (){
    if (!this.resultTrue && this.queIndex > 0) {
        if (this.isgoId) {
				this.getupAnswerStaging()
			} else {
				this.getaddAnswerStaging()
			}
    }
    
  
  },
  mounted() {
    if (this.isgoId) {
      this.http = this.Services.ossUpload;
      this.getDataList()
    } else {
     this.http = this.Services.ossUpload;
     this.quename = this.$route.query.name;
     this.selfId = this.$route.query.id;
     this.ageCurrent = -1;
     this.getSelfDiagnosisQuestionList();
   }

    // else if (this.selectAnswerUser.answerSex != null && this.selectAnswerUser.answerAge == null) {
    //   this.ageName = this.selectAnswerUser.answerAge;
    //   this.ageCurrent = 2;
    // } else if (this.selectAnswerUser.answerSex == null && this.selectAnswerUser.answerAge != null) {
    //   this.ageName = this.selectAnswerUser.answerAge;
    //   this.ageCurrent = 1;
    // } else if (this.selectAnswerUser.answerSex == null && this.selectAnswerUser.answerAge == null) {
    //   this.ageName = this.selectAnswerUser.answerAge;
    //   this.ageCurrent = 1;
    // }
  },
  methods: {
    toPage(){
        this.$router.back()
    },
    goDelete(){
        this.testDomain.deleteAnswerStaging(this.isgoId,
          res => {

          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
			},
    getDataList(){
      this.testDomain.getAnswerStaging(this.isgoId,
          res => {
            this.isgoData = res.data
						this.selfId = res.data.answerId
						this.quename = res.data.answerName
						this.ageCurrent = -1
						this.isgoDataList = JSON.parse(res.data.data)
            this.queIndex = res.data.questionIndex
						this.getSelfDiagnosisQuestionList()
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
			},
      
    getupAnswerStaging(){
				let paramresList = []
				for (let j=0; j<this.queIndex; j++) {
					paramresList.push(this.questionList[j])
				}
				let resultParams = {}
				resultParams.id = this.isgoId
				resultParams.questionIndex = this.queIndex
				resultParams.questionTotal = this.questionList.length
				resultParams.data = JSON.stringify(paramresList)
        this.testDomain.putAnswerStaging(
        resultParams,
          res => {
            // this.$message({
            //   type: "success",
            //   message: "添加成功!",
            // });
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );
			},
    getaddAnswerStaging(){
				let paramresList = []
				for (let j=0; j<this.queIndex; j++) {
					paramresList.push(this.questionList[j])
				}
        let resultParams = {}
        resultParams.answerId = this.selfId * 1
				resultParams.answerName = this.quename
				resultParams.questionIndex = this.queIndex
				resultParams.questionTotal = this.questionList.length
				resultParams.data = JSON.stringify(paramresList)
				resultParams.answerUserId = this.selectAnswerUser.id
        this.testDomain.addAnswerStaging(this.noTexttype,
        resultParams,
          res => {
            // this.$message({
            //   type: "success",
            //   message: "添加成功!",
            // });
          },
          error => {
            console.log("请求失败!请刷新重试:", error);
          }
        );

    },
    NextQuestion(item, index) {
      var _this = this;
      if (_this.optionname) {
        setTimeout(() => {
          _this.queIndex++;
          for (let i = 0; i < _this.questionList.length; i++) {
            if (_this.questionList[i].optionIdMultiple) {
              _this.$set(_this.questionList[i], "optionIdMultiple", false);
            }
          }
        }, 500);
      }
    },
    upQuestion() {
      var _this = this;
      if (_this.queIndex > 0) {
        setTimeout(() => {
          _this.queIndex--;
          for (let i = 0; i < _this.questionList.length; i++) {
            if (_this.questionList[i].optionIdMultiple) {
              _this.$set(_this.questionList[i], "optionIdMultiple", false);
            }
          }
        }, 500);
      }
    },
    gosexNext(e, index) {
      this.answerSex = index;
      if (this.ageName != null) {
        setTimeout(() => {
          this.sexName = e.text;
          this.getSelfDiagnosisQuestionList();
          this.ageCurrent = -1;
        }, 500);
      } else {
        setTimeout(() => {
          this.sexName = e.text;
          this.ageCurrent = 2;
        }, 500);
      }
    },
    goageNext(e, index) {
      this.answerAge = index;
      setTimeout(() => {
        if (e.value == 0) {
          this.ageName = 0;
        } else if (e.value == 1) {
          this.ageName = 7;
        } else if (e.value == 2) {
          this.ageName = 13;
        } else if (e.value == 3) {
          this.ageName = 18;
        } else if (e.value == 4) {
          this.ageName = 46;
        } else {
          this.ageName = 69;
        }
        this.getSelfDiagnosisQuestionList();
        this.ageCurrent = -1;
      }, 500);
    },
    bindChangeage(e, index, item) {
      let _this = this;
      if (_this.nextClick == true) {
        _this.nextClick = false;
        for (let i = 0; i < _this.questionList.length; i++) {
          if (_this.questionList[i].id == item.id) {
            _this.questionList[i].optionId = e.id;
            _this.indexRa = index;
            _this.questionList[i].optionValue = e;
            _this.gonextque();
          }
        }
      } else {
        return;
      }
    },
    bindChangeageradio(e, index, item) {
      let _this = this;
      if (_this.nextClick == true) {
        _this.nextClick = false;
        for (let i = 0; i < _this.questionList.length; i++) {
          if (_this.questionList[i].id == item.id) {
            _this.questionList[i].optionId = e.id;
            _this.indexRa = index;
            _this.questionList[i].optionValue = e;
            _this.gonextque();
          }
        }
      } else {
        return;
      }
    },
    // 延迟跳转
    gonextque() {
      let _this = this;
      setTimeout(() => {
        if (_this.queIndex < _this.QuestionTotal - 1) {
          _this.queIndex++;
          _this.nextClick = true;
          _this.indexRa = -1;
          let newQuestionTotal = 0;
          newQuestionTotal = _this.QuestionTotal - 1;
          _this.percentnum = (100 / newQuestionTotal) * _this.queIndex;
        } else {
          _this.nextClick = true;
        }
      }, 500);
    },
    gotextopMultiple(e, index, item) {
      e.checked = !e.checked;
      if (e.description == "以上都不是") {
        if (e.checked == true) {
          for (let i = 0; i < this.questionList.length; i++) {
            if (this.questionList[i].id == item.id) {
              for (let j = 0; j < this.questionList[i].selfDiagnosisQuestionOption.length; j++) {
                if (this.questionList[i].selfDiagnosisQuestionOption[j].description != "以上都不是") {
                  this.questionList[i].selfDiagnosisQuestionOption[j].checked = false;
                  this.questionList[i].selfDiagnosisQuestionOption[j].ischecked = true;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.questionList.length; i++) {
            if (this.questionList[i].id == item.id) {
              for (let j = 0; j < this.questionList[i].selfDiagnosisQuestionOption.length; j++) {
                if (this.questionList[i].selfDiagnosisQuestionOption[j].description != "以上都不是") {
                  this.questionList[i].selfDiagnosisQuestionOption[j].checked = false;
                  this.questionList[i].selfDiagnosisQuestionOption[j].ischecked = false;
                }
              }
            }
          }
        }
      }
    },
    gosearchimgMultiple(e, index, item) {
      e.checked = !e.checked;
      if (e.description == "以上都不是") {
        if (e.checked == true) {
          for (let i = 0; i < this.questionList.length; i++) {
            if (this.questionList[i].id == item.id) {
              for (let j = 0; j < this.questionList[i].selfDiagnosisQuestionOption.length; j++) {
                if (this.questionList[i].selfDiagnosisQuestionOption[j].description != "以上都不是") {
                  this.questionList[i].selfDiagnosisQuestionOption[j].checked = false;
                  this.questionList[i].selfDiagnosisQuestionOption[j].ischecked = true;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.questionList.length; i++) {
            if (this.questionList[i].id == item.id) {
              for (let j = 0; j < this.questionList[i].selfDiagnosisQuestionOption.length; j++) {
                if (this.questionList[i].selfDiagnosisQuestionOption[j].description != "以上都不是") {
                  this.questionList[i].selfDiagnosisQuestionOption[j].checked = false;
                  this.questionList[i].selfDiagnosisQuestionOption[j].ischecked = false;
                }
              }
            }
          }
        }
      }
    },
    // 题库
    getSelfDiagnosisQuestionList() {
      var _this = this;
      // let isGender = false;
      // if (_this.sexName == "男") {
      //   isGender = false;
      // } else {
      //   isGender = true;
      // }
      if (_this.selectAnswerUser.answerAge == 0) {
        _this.ageName = 0
				} else if (_this.selectAnswerUser.answerAge  == 1) {
					_this.ageName = 7
				} else if (_this.selectAnswerUser.answerAge  == 2) {
					_this.ageName = 13
				} else if (_this.selectAnswerUser.answerAge  == 3) {
					_this.ageName = 18
				} else if (_this.selectAnswerUser.answerAge  == 4) {
					_this.ageName = 46
				} else {
					_this.ageName = 69
				}
      _this.zdrugconsultationMain.getSelfDiagnosisQuestion(
        _this.selfId,
        _this.selectAnswerUser.answerSex,
        _this.ageName,
        function (data) {
          if (_this.isgoId) {
            for (let i = _this.queIndex; i < data.data.length; i++) {
              data.data[i].arryIndex = 0;
              if (data.data[i].fileUrl) {
                data.data[i].fileUrl = JSON.parse(data.data[i].fileUrl);
              }
              for (let j = 0; j < data.data[i].selfDiagnosisQuestionOption.length; j++) {
                if (data.data[i].selfDiagnosisQuestionOption[j].fileUrl && data.data[i].selfDiagnosisQuestionOption[j].fileUrl != "null") {
                  data.data[i].selfDiagnosisQuestionOption[j].fileUrl = JSON.parse(data.data[i].selfDiagnosisQuestionOption[j].fileUrl);
                  data.data[i].imgTrue = true;
                }
                if (data.data[i].type == "Multiple") {
                  data.data[i].selfDiagnosisQuestionOption[j].checked = false;
                }
              }
              _this.isgoDataList.push(data.data[i])
            }
						_this.QuestionTotal = data.data.length
						_this.questionList = _this.isgoDataList
						_this.processList.push(_this.questionList[_this.questindex])
          } else {
            for (let i = 0; i < data.data.length; i++) {
              data.data[i].arryIndex = 0;
              if (data.data[i].fileUrl) {
                data.data[i].fileUrl = JSON.parse(data.data[i].fileUrl);
              }
              for (let j = 0; j < data.data[i].selfDiagnosisQuestionOption.length; j++) {
                if (data.data[i].selfDiagnosisQuestionOption[j].fileUrl && data.data[i].selfDiagnosisQuestionOption[j].fileUrl != "null") {
                  data.data[i].selfDiagnosisQuestionOption[j].fileUrl = JSON.parse(data.data[i].selfDiagnosisQuestionOption[j].fileUrl);
                  data.data[i].imgTrue = true;
                }
                if (data.data[i].type == "Multiple") {
                  data.data[i].selfDiagnosisQuestionOption[j].checked = false;
                }
              }
            }
            _this.QuestionTotal = data.data.length;
            _this.questionList = data.data;
            _this.processList.push(_this.questionList[_this.questindex]);
          }
       
        },
        function (error) {
          console.log(error);
        }
      );
    },
    gotextDiagnosis(item, index) {
      this.Diagnosiscategoryindex = index;
      this.showPrimarySymptomName = item.primarySymptomName;
      this.getSelfDiagnosisPrimarySymptomDetailList(item.id);
      this.selfId = item.id;
    },
    goresult() {
      let params = [];
      let params1 = [];
      for (let i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].type == "Radio") {
          if (this.questionList[i].optionId) {
            params1.push({
              questionName: this.questionList[i].description,
              questionFileUrl: JSON.stringify(this.questionList[i].fileUrl),
              questionOptionName: this.questionList[i].optionValue.description,
              questionOptionFileUrl: JSON.stringify(this.questionList[i].optionValue.fileUrl),
              symptom: this.questionList[i].optionValue.relationSymptoms,
              rank: this.questionList[i].rank,
            });
          } else {
            params1.push({
              questionName: this.questionList[i].description,
              questionFileUrl: JSON.stringify(this.questionList[i].fileUrl),
              questionOptionName: this.questionList[i].selfDiagnosisQuestionOption[0].description,
              questionOptionFileUrl: JSON.stringify(this.questionList[i].selfDiagnosisQuestionOption[0].fileUrl),
              symptom: this.questionList[i].selfDiagnosisQuestionOption[0].relationSymptoms,
              rank: this.questionList[i].rank,
            });
          }
        } else {
          let isChecked = this.questionList[i].selfDiagnosisQuestionOption.some(item => item.checked == true);
          if (isChecked) {
            for (let j = 0; j < this.questionList[i].selfDiagnosisQuestionOption.length; j++) {
              if (this.questionList[i].selfDiagnosisQuestionOption[j].checked == true) {
                params.push({
                  questionName: this.questionList[i].description,
                  questionFileUrl: JSON.stringify(this.questionList[i].fileUrl),
                  questionOptionName: this.questionList[i].selfDiagnosisQuestionOption[j].description,
                  questionOptionFileUrl: JSON.stringify(this.questionList[i].selfDiagnosisQuestionOption[j].fileUrl),
                  symptom: this.questionList[i].selfDiagnosisQuestionOption[j].relationSymptoms,
                  rank: this.questionList[i].rank,
                });
              }
            }
          } else {
            params.push({
              questionName: this.questionList[i].description,
              questionFileUrl: JSON.stringify(this.questionList[i].fileUrl),
              questionOptionName: this.questionList[i].selfDiagnosisQuestionOption[0].description,
              questionOptionFileUrl: JSON.stringify(this.questionList[i].selfDiagnosisQuestionOption[0].fileUrl),
              symptom: this.questionList[i].selfDiagnosisQuestionOption[0].relationSymptoms,
              rank: this.questionList[i].rank,
            });
          }
        }
      }
      // let isGender = false;
      // if (this.sexName == "男") {
      //   isGender = false;
      // } else {
      //   isGender = true;
      // }
      let resultParams = {};
      resultParams.primarySymptomId = this.selfId;
      resultParams.age = this.ageName;
      resultParams.gender = this.selectAnswerUser.answerSex;
      resultParams.userPhone = this.selectAnswerUser.answerPhone;
      resultParams.userName = this.selectAnswerUser.answerUserName;
      resultParams.answerDiseaseName = this.selectAnswerUser.diseaseName;
      resultParams.recordInfo = params.concat(params1);
      if (this.Services.ClientId == 'AssociationWeb') {
        resultParams.cooperationType = 1
      }
      let _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisQuestionSubmit(
        resultParams,
        function (data) {
          _this.resultTrue = true
          _this.goDelete()
          _this.$router.push({
            path: "/diseaseConsultation/diseaseDetail",
            query: {
              id: data.data.recordId,
            },
          });
        },
        function (error) {
          console.log(error);
        }
      );
    },
    gotexsex(e, index) {
      this.sexShowName = e.name;
      this.sexindex = index;
    },
    gosexSure() {
      this.sexName = this.sexShowName;
      this.sexShow = false;
      this.ageShow = true;
    },
    goAgeSure() {
      this.ageName = this.ageShowName;
      this.ageShow = false;
      this.getSelfDiagnosisQuestionList();
    },
    handleChangeAge(value) {
      this.ageShowName = value;
    },
    gotextop(e, index) {
      this.opcategoryindex = index;
      this.optionname = e;
    },
    gorightresult() {
      var _this = this;
      let params = [];
      let params1 = [];
      for (let i = 0; i < _this.processList.length; i++) {
        if (_this.processList[i].questionOptionList.length > 0) {
          for (let j = 0; j < _this.processList[i].questionOptionList.length; j++) {
            params1.push({
              questionName: _this.processList[i].description,
              questionFileUrl: JSON.stringify(_this.processList[i].fileUrl),
              questionOptionName: _this.processList[i].questionOptionList[j].description,
              questionOptionFileUrl: JSON.stringify(_this.processList[i].questionOptionList[j].fileUrl),
              symptom: _this.processList[i].questionOptionList.relationSymptoms,
              rank: _this.processList[i].rank,
            });
          }
        } else {
          params.push({
            questionName: _this.processList[i].description,
            questionFileUrl: JSON.stringify(_this.processList[i].fileUrl),
            questionOptionName: _this.processList[i].questionOptionList.description,
            questionOptionFileUrl: JSON.stringify(_this.processList[i].questionOptionList.fileUrl),
            symptom: _this.processList[i].questionOptionList.relationSymptoms,
            rank: _this.processList[i].rank,
          });
        }
      }
      let isGender = false;
      if (_this.sexName == "男") {
        isGender = false;
      } else {
        isGender = true;
      }
      let resultParams = {};
      resultParams.primarySymptomId = _this.selfId;
      resultParams.age = _this.ageName;
      resultParams.gender = isGender;
      resultParams.userPhone = this.selectAnswerUser.answerPhone;
      resultParams.userName = this.selectAnswerUser.answerUserName;
      resultParams.answerDiseaseName = this.selectAnswerUser.diseaseName;
      resultParams.recordInfo = params.concat(params1);
      _this.zdrugconsultationMain.getSelfDiagnosisQuestionSubmit(
        resultParams,
        function (data) {
          _this.zdrugconsultationMain.getUserSelfDiagnosisRecordResult(
            data.data.recordId,
            function (data) {
              _this.resultList = data.data;
              _this.resultList.diagnosisTime = _this.resultList.diagnosisTime.slice(0, 10);
              let contantList = [];
              for (let i = 0; i < _this.resultList.recordResults.length; i++) {
                contantList = _this.resultList.recordResults[i].pulseSymptoms.concat(_this.resultList.recordResults[i].symptoms.concat(_this.resultList.recordResults[i].tongueSymptoms));
                _this.resultList.recordResults[i].contantLists = contantList;
                let newsyndromeSymptoms1 = [];
                for (let k = 0; k < _this.resultList.recordResults[i].syndromeSymptoms.length; k++) {
                  newsyndromeSymptoms1.push({
                    name: _this.resultList.recordResults[i].syndromeSymptoms[k],
                    checked: false,
                  });
                }
                _this.resultList.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
              }
              for (let m = 0; m < _this.resultList.recordResults.length; m++) {
                for (let n = 0; n < _this.resultList.recordResults[m].contantLists.length; n++) {
                  for (let j = 0; j < _this.resultList.recordResults[m].newsyndromeSymptoms.length; j++) {
                    if (_this.resultList.recordResults[m].newsyndromeSymptoms[j].name == _this.resultList.recordResults[m].contantLists[n]) {
                      _this.resultList.recordResults[m].newsyndromeSymptoms[j].checked = true;
                    }
                  }
                }
              }
            },
            function (error) {
              _this.resultList = data.data;
              console.log(error);
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    chooseAnswerMultiple(item) {
      let isChecked = item.selfDiagnosisQuestionOption.some(item => item.checked == true);
      if (isChecked) {
        for (let i = 0; i < this.questionList.length; i++) {
          if (this.questionList[i].id == item.id) {
            // this.questionList[i]['optionIdMultiple'] = true
            this.$set(this.questionList[i], "optionIdMultiple", true);
            this.gonextque();
          }
        }
      } else {
        this.$message.error("您尚未选择内容");
        return;
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";
.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.leftbox {
  display: flex;
  align-items: center;
}
.leftMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 0px 15px 15px 15px;
  margin-left: 10px;
}
.resultMessage {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  margin-left: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.16);
  border-radius: 16px;
}
.rightbox {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.rightMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #ee9e51;
  border-radius: 15px 0px 15px 15px;
  color: #fff;
}
.startBox {
  width: 1200px;
  margin: 30px auto;
  padding: 40px;
  box-shadow: 0px 6px 10px 0px rgba(204, 204, 204, 0.18);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/img/zdrugConsult/zdrugStartChart.jpg");
}
.startBoxleft {
  position: relative;
  width: 100%;
  min-height: 720px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}
.startBoxleftTop {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
/*滚动条样式*/
.startBoxleftTop::-webkit-scrollbar {
  width: 4px;
}
.startBoxleftTop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.startBoxleftTop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.startBoxleftBot {
  /* overflow-x:scroll; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
  border-top: 1px solid #e6e6e6;
  position: relative;
}
.startBoxleftBotn {
  margin-top: 12px;
  text-align: right;
  margin-right: 30px;
}
.startBoxright {
  margin-left: 5%;
  width: 30%;
  height: 720px;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
}
.surebtn {
  background: linear-gradient(180deg, #ffb163, #ee9e51);
  font-size: 16px;
  color: #ffffff;
  padding: 17rpx 0;
  width: 100%;
  text-align: center;
}
.goodTagCheck {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
  margin-top: 10px;
  cursor: pointer;
}
.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
/* 症状 */
.textdetail {
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 12px;
}
.viptable {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.viptableY {
  width: 100%;
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
}
.endbox {
  margin-top: -50px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
}
.endboxright {
  margin-top: 15px;
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
  cursor: pointer;
}
.ring {
  width: 12px;
  height: 12px;
  border: 3px solid #cf7f31;
  box-shadow: 0px 2px 3px 0px rgba(202, 127, 55, 0.3);
  border-radius: 50%;
}
.endbox1 {
  margin-top: 20px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(214, 214, 214, 0.3);
  border-radius: 10px;
  position: relative;
  border: 1px solid #ffe5cb;
}
.ring1 {
  width: 6px;
  height: 6px;
  background: #df7d1f;
  border-radius: 50%;
}
.ring2 {
  width: 6px;
  height: 6px;
  background: #3bc8d9;
  border-radius: 50%;
}
.ring3 {
  width: 6px;
  height: 6px;
  background: #68caa9;
  border-radius: 50%;
}
.boxtag2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #3bc8d9;
  font-size: 24rpx;
}
.boxtag3 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background: #68caa9;
  font-size: 12px;
}
.boxtag1 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #f6a11f;
  font-size: 12px;
}
.flexlr {
  display: flex;
  justify-content: space-between;
}
.resultimg {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-image: url("../../assets/img/zdrugConsult/resultback.png");
}
.rightBottom {
  padding: 10px 25px;
  background: #f0f2f2;
  border-radius: 25px;
  font-size: 13px;
  color: #999999;
  text-align: center;
}
.rightBottomBox {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}
.radioBox {
  margin: 10px 0;
  font-size: 18px;
}
.imgTag {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 8px;
}
.imgTagCheck {
  background-color: #feaf62;
  /* opacity: 0.5; */
  padding: 5px;
}
.textCheck {
  color: #df7d1f;
}
.textBox {
  display: flex;
  flex-wrap: wrap;
}
.symptomsText {
  font-size: 15px;
  color: #666666;
  margin: 8px 0;
  padding-left: 22px;
  /* overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
}
.itembox {
  width: 50%;
}
.nodataimg {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.hide-tooltip {
  max-width: 400px;
  /* visibility: hidden; */
}
.newChecked {
  color: #df7d1f;
}
.drugTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-right: 40px;
  align-items: center;
}
.drugTitle1 {
  font-size: 28px;
  color: #333333;
}
.zdrugInputclass {
  width: 100%;
  padding: 10px 20px;
  background: #fafafa;
  border: 1px solid #e7a365;
  border-radius: 20px;
  font-size: 20px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.zdrugInputIcon {
  padding: 7px 17px;
  border-radius: 17px;
  background-color: #e7a365;
  color: #fff;
}
.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.quebox {
  margin: 0 20px;
  margin-top: 40px;
  background: #ffffff;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.3);
  border-radius: 24px;
  padding: 32px 40px;
}
.test-tip {
  font-size: 18px;
  color: #333333;
  padding-left: 11px;
}

.test-tip span {
  display: block;
  color: #ff0000;
  font-size: 18px;
}
.question-option {
  margin-top: 35px;
  padding: 0 30px;
  cursor: pointer;
}

.question-option text {
  display: block;
  padding: 26px 0;
  border-radius: 12px;
  background-color: #ffffff;
  border: 1px solid #6ec6bb;
  text-align: center;
  color: #019582;
}
.titleBoxText {
  font-size: 18px;
}
.titleBoxTextimage {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.titleBoxTextimage img {
  width: 100%;
  height: 100%;
}
.active {
  color: #df7d1f;
}
.queston-tit {
  margin: 30px 0;
  padding: 22px 27px 28px 21px;
  background: #f5f5f5;
  border-radius: 12px;
  font-size: 18px;
}
.optionsImgbox {
  width: 245px;
  margin: 0 10px;
}

.optionsImgbox img {
  width: 100%;
  /* border-radius: 12rpx; */
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}

.prev-btn {
  margin-left: 30px;
  margin-top: 30px;
  float: left;
  font-size: 19px;
  color: #ffffff;
  background-color: #e89951;
  padding: 12px 23px;
  border-radius: 8px;
  cursor: pointer;
}

.next-btn {
  margin-right: 30px;
  margin-top: 30px;
  float: right;
  font-size: 19px;
  color: #ffffff;
  background-color: #e89951;
  padding: 12px 23px;
  border-radius: 8px;
  cursor: pointer;
}

.result-btn {
  margin-top: 30px;
  padding: 12px 23px;
  border-radius: 8px;
  background: linear-gradient(-90deg, #ff8916, #f3bd49);
  text-align: center;
  color: #ffffff;
  font-size: 19px;
  cursor: pointer;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-right {

}
.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #333333;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #333333;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #00ab98;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
